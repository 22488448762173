<template>
	<CRow>
		<CCol md="12 pt-3">
			<h2 class="typo-h4">
				Schedule
			</h2>
		</CCol>
		<CCol md="12" class="d-flex pt-4">
			<div class="flex-fill">
				<h4>Pre-order period</h4>
				<p class="description typo-helper-text color-black-45">
					The period to pre-order products in this campaign.
				</p>
			</div>
			<div v-if="isEdit">
				<CBadge
					:color="getStatusBadgeColor(periodStatus)"
					class="badge-status"
				>
					{{ getStatusBadgeTitle(periodStatus) }}
				</CBadge>
			</div>
		</CCol>
		<CCol md="4" class="mt-2">
			<BaseInputDate
				v-model="$v.localPreOrderStartDate.$model"
				:is-valid="!$v.localPreOrderStartDate.$error"
				label="Start date*"
				invalid-feedback="Start date is required"
				placeholder="Select date"
				@input="handlePreOrderStartDateChange"
			>
				<template #append-content>
					<CIcon class="cil-calendar" name="cil-calendar" />
				</template>
			</BaseInputDate>
		</CCol>
		<CCol md="2" class="mt-2">
			<BaseInputTime
				v-model="$v.localPreOrderStartTime.$model"
				:is-valid="!$v.localPreOrderStartTime.$error"
				:invalid-feedback="getTimeValidationMessage($v.localPreOrderStartTime)"
				label="Start time*"
				@input="(value) => $emit('update:preOrderStartTime', value)"
			/>
		</CCol>
		<CCol md="4" class="mt-2">
			<BaseInputDate
				v-model="$v.localPreOrderEndDate.$model"
				:min-date="localPreOrderStartDate"
				:is-valid="!$v.localPreOrderEndDate.$error"
				:invalid-feedback="$t('global.error.required')"
				label="End date"
				placeholder="Select date"
				data-test-id="end-date"
				@input="(value) => $emit('update:preOrderEndDate', value)"
			>
				<template #append-content>
					<CIcon class="cil-calendar" name="cil-calendar" />
				</template>
			</BaseInputDate>
		</CCol>
		<CCol md="2" class="mt-2">
			<BaseInputTime
				v-model="$v.localPreOrderEndTime.$model"
				:is-valid="!$v.localPreOrderEndTime.$error"
				:invalid-feedback="getTimeValidationMessage($v.localPreOrderEndTime)"
				label="End time"
				@input="(value) => $emit('update:preOrderEndTime', value)"
			/>
		</CCol>
		<CCol md="12" class="mt-4">
			<StoreFrontVisibility
				id="time-counter"
				:is-visible="isTimeCounterVisible"
				:visible-labels="['Show', 'Hide']"
				label="Time counter"
				image-url="/assets/images/time-counter-annotation.svg"
				image-alt="Time counter annotation"
				description="Display countdown timer before and during the pre-order period. The countdown timer during the pre-order period won’t be displayed if the end date and time is not set."
				@onChecked="(value) => $emit('update:isTimeCounterVisible', value)"
			/>
		</CCol>
		<template v-if="!isPaymentOptionFullPriceOnly">
			<CCol md="12" class="d-flex mt-4">
				<div class="flex-fill">
					<h4>Remaining payment period</h4>
					<p class="description typo-helper-text color-black-45">
						The period to pay remaining balance. this period can not set lower than pre-order period.
					</p>
				</div>
				<div v-if="isEdit">
					<CBadge
						:color="getStatusBadgeColor(remainingStatus)"
						class="badge-status"
					>
						{{ getStatusBadgeTitle(remainingStatus) }}
					</CBadge>
				</div>
			</CCol>
			<CCol md="4" class="mt-2">
				<BaseInputDate
					v-model="$v.localPaymentStartDate.$model"
					:is-valid="!$v.localPaymentStartDate.$error"
					:min-date="minimumRemainingPaymentDate"
					:label="paymentStartDateLabel"
					invalid-feedback="Start date is required"
					placeholder="Select date"
					@input="(value) => $emit('update:paymentStartDate', value)"
				>
					<template #append-content>
						<CIcon class="cil-calendar" name="cil-calendar" />
					</template>
				</BaseInputDate>
			</CCol>
			<CCol md="2" class="mt-2">
				<BaseInputTime
					v-model="$v.localPaymentStartTime.$model"
					:is-valid="!$v.localPaymentStartTime.$error"
					:invalid-feedback="getTimeValidationMessage($v.localPaymentStartTime)"
					:label="paymentStartTimeLabel"
					@input="(value) => $emit('update:paymentStartTime', value)"
				/>
			</CCol>
			<CCol md="4" class="mt-2 mb-3">
				<BaseInputDate
					v-model="$v.localPaymentEndDate.$model"
					:min-date="localPaymentStartDate"
					:is-valid="!$v.localPaymentEndDate.$error"
					:invalid-feedback="$t('global.error.required')"
					label="End date"
					placeholder="Select date"
					data-test-id="end-date"
					@input="(value) => $emit('update:paymentEndDate', value)"
				>
					<template #append-content>
						<CIcon class="cil-calendar" name="cil-calendar" />
					</template>
				</BaseInputDate>
			</CCol>
			<CCol md="2" class="mt-2 mb-3">
				<BaseInputTime
					v-model="$v.localPaymentEndTime.$model"
					:is-valid="!$v.localPaymentEndTime.$error"
					:invalid-feedback="getTimeValidationMessage($v.localPaymentEndTime)"
					label="End time"
					@input="(value) => $emit('update:paymentEndTime', value)"
				/>
			</CCol>
		</template>
		<CCol md="12" class="d-flex mt-4">
			<div class="flex-fill">
				<h4>Product available date</h4>
				<p class="description typo-helper-text color-black-45">
					Campaign status will be changed to inactive on this date and SKU will be changed to a normal SKU. Leave it blank to change campaign status manually.
				</p>
			</div>
			<div>
				<CBadge
					:color="getStatusBadgeColor(releasedStatus)"
					class="badge-status"
				>
					{{ getStatusBadgeTitle(releasedStatus) }}
				</CBadge>
			</div>
		</CCol>
		<CCol md="4" class="mt-2 mb-3">
			<BaseInputDate
				v-model="$v.localReleaseDate.$model"
				:is-valid="!$v.localReleaseDate.$error"
				:invalid-feedback="$t('global.error.required')"
				label="Date"
				placeholder="Select date"
				@input="(value) => $emit('update:releaseDate', value)"
			>
				<template #append-content>
					<CIcon class="cil-calendar" name="cil-calendar" />
				</template>
			</BaseInputDate>
		</CCol>
		<CCol md="2" class="mt-2 mb-3">
			<BaseInputTime
				v-model="$v.localReleaseTime.$model"
				:is-valid="!$v.localReleaseTime.$error"
				:invalid-feedback="getTimeValidationMessage($v.localReleaseTime)"
				label="Time"
				@input="(value) => $emit('update:releaseTime', value)"
			/>
		</CCol>
		<CCol md="12" class="mt-4">
			<h4>Available date information</h4>
			<p class="description typo-helper-text color-black-45">
				This information will be displayed in 2 locations on the product detail. Leave it blank to hide this information.
			</p>
		</CCol>
		<CCol md="12">
			<div class="relase-info">
				<CRow class="mb-2">
					<CCol md="3">
						<img
							src="/assets/images/release-info-annotation-1.svg"
							alt="release info"
							data-test-id="release-info-annotation-1"
						>
					</CCol>
					<CCol md="9">
						<label data-test-id="sku-number-label">
							Location 1: Next to SKU number
						</label>
						<p
							data-test-id="sku-number-description"
							class="relase-info-description"
						>
							The message below will be displayed next to SKU number on product detail page.
						</p>
					</CCol>
				</CRow>
				<CInput
					v-model.trim="localReleaseTitleTh"
					data-test-id="release-info-title-en"
					class="mb-0"
					label="Message (TH)"
					placeholder="E.g. วางจำหน่าย: 23 มิ.ย. 2564"
					@input="(value) => $emit('update:releaseTitleTh', value)"
				/>
				<CInput
					v-model.trim="localReleaseTitleEn"
					data-test-id="release-info-title-en"
					class="mb-2"
					label="Message (EN)"
					placeholder="E.g. Available date: 23 Jun 2564"
					@input="(value) => $emit('update:releaseTitleEn', value)"
				/>
			</div>
			<div class="relase-info mt-4 mb-2">
				<CRow class="mb-2">
					<CCol md="3">
						<img
							src="/assets/images/release-info-annotation-2.svg"
							alt="release info"
							data-test-id="release-info-annotation-2"
						>
					</CCol>
					<CCol md="9">
						<label data-test-id="product-variant-release-label">
							Location 2: Below a product variant selection
						</label>
						<p
							data-test-id="product-variant-release-description"
							class="relase-info-description"
						>
							The message below will be displayed below a product variant selection on product detail page.
						</p>
					</CCol>
				</CRow>
				<CInput
					v-model.trim="localReleaseDescriptionTh"
					data-test-id="product-variant-release-description-th"
					class="mb-0"
					label="Message (TH)"
					placeholder="E.g. สินค้าจะวางจำหน่ายในวันที่ 23 มิ.ย. 2564"
					@input="(value) => $emit('update:releaseDescriptionTh', value)"
				/>
				<CInput
					v-model.trim="localReleaseDescriptionEn"
					data-test-id="product-variant-release-description-en"
					class="mb-2"
					label="Message (EN)"
					placeholder="E.g. This product will be available on 23 Jun 2564"
					@input="(value) => $emit('update:releaseDescriptionEn', value)"
				/>
			</div>
		</CCol>
		<CCol md="12">
			<hr class="mt-4">
		</CCol>
	</CRow>
</template>

<script>
import dayjs from 'dayjs';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import StoreFrontVisibility from '@/components/StoreFrontVisibility.vue';

import { PREORDER_PAYMENT, PREORDER_TITLE, PREORDER_COLOR } from '../enums/preorders';
import { after, minTime, timeFormat } from '../assets/js/validators';

export default {
	name: 'PreOrderSchedule',
	components: {
		StoreFrontVisibility,
	},
	validations() {
		let schema = {
			localPreOrderStartDate: {
				required,
			},
			localPreOrderStartTime: {
				required,
				timeFormat,
			},
			localPreOrderEndDate: {
				required: requiredIf(() => this.localPreOrderEndTime),
			},
			localPreOrderEndTime: {
				required: requiredIf(() => this.localPreOrderEndDate),
				timeFormat,
			},
			localPaymentStartDate: {
				required: requiredIf(() => !this.isPaymentOptionFullPriceOnly),
			},
			localPaymentStartTime: {
				required: requiredIf(() => !this.isPaymentOptionFullPriceOnly),
				timeFormat,
			},
			localPaymentEndDate: {
				required: requiredIf(() => !this.isPaymentOptionFullPriceOnly && this.localPaymentEndTime),
			},
			localPaymentEndTime: {
				required: requiredIf(() => !this.isPaymentOptionFullPriceOnly && this.localPaymentEndDate),
				timeFormat,
			},
			localReleaseDate: {
				required: requiredIf(() => this.localReleaseTime),
			},
			localReleaseTime: {
				required: requiredIf(() => this.localReleaseDate),
				timeFormat,
			},
		};

		// If start date same as end date, we need to validation time.
		if (
			this.localPreOrderStartDate
			&& this.localPreOrderEndDate
			&& dayjs(this.localPreOrderStartDate).isSame(dayjs(this.localPreOrderEndDate), 'day')
		) {
			schema = {
				...schema,
				localPreOrderEndTime: {
					...schema.localPreOrderEndTime,
					after: after('localPreOrderStartTime', 'localPreOrderEndTime'),
				},
			};
		}

		// If start date same as end date, we need to validation time.
		if (
			this.localPaymentStartDate
			&& this.localPaymentEndDate
			&& dayjs(this.localPaymentStartDate).isSame(dayjs(this.localPaymentEndDate), 'day')
		) {
			schema = {
				...schema,
				localPaymentEndTime: {
					...schema.localPaymentEndTime,
					after: after('localPaymentStartTime', 'localPaymentEndTime'),
				},
			};
		}

		// Validate remaining payment start time
		if (
			this.localPreOrderStartDate
			&& this.localPaymentStartDate
			&& dayjs(this.localPreOrderStartDate).isSame(dayjs(this.localPaymentStartDate), 'day')
		) {
			schema = {
				...schema,
				localPaymentStartTime: {
					...schema.localPaymentStartTime,
					minTime: minTime('localPreOrderStartTime', 'localPaymentStartTime'),
				},
			};
		}

		return schema;
	},
	mixins: [validationMixin],
	props: {
		isEdit: {
			type: Boolean,
			default: false,
		},
		preOrderPaymentOption: {
			type: String,
			default: '',
		},
		preOrderStartDate: {
			type: [String, Date],
			default: null,
		},
		preOrderStartTime: {
			type: String,
			default: '',
		},
		preOrderEndDate: {
			type: [String, Date],
			default: null,
		},
		preOrderEndTime: {
			type: String,
			default: '',
		},
		isTimeCounterVisible: {
			type: Boolean,
			default: true,
		},
		paymentStartDate: {
			type: [String, Date],
			default: null,
		},
		paymentStartTime: {
			type: String,
			default: '',
		},
		paymentEndDate: {
			type: [String, Date],
			default: null,
		},
		paymentEndTime: {
			type: String,
			default: '',
		},
		periodStatus: {
			type: String,
			default: '',
		},
		remainingStatus: {
			type: String,
			default: '',
		},
		releasedStatus: {
			type: String,
			default: '',
		},
		releaseDate: {
			type: [String, Date],
			default: null,
		},
		releaseTime: {
			type: String,
			default: '',
		},
		releaseTitleEn: {
			type: String,
			default: null,
		},
		releaseTitleTh: {
			type: String,
			default: null,
		},
		releaseDescriptionTh: {
			type: String,
			default: null,
		},
		releaseDescriptionEn: {
			type: String,
			default: null,
		},
	},
	data() {
		const {
			preOrderStartDate,
			preOrderStartTime,
			preOrderEndDate,
			preOrderEndTime,
			paymentStartDate,
			paymentStartTime,
			paymentEndDate,
			paymentEndTime,
			releaseDate,
			releaseTime,
			releaseTitleEn,
			releaseTitleTh,
			releaseDescriptionEn,
			releaseDescriptionTh,
		} = this || {};
		return {
			localPreOrderStartDate: preOrderStartDate,
			localPreOrderStartTime: preOrderStartTime,
			localPreOrderEndDate: preOrderEndDate,
			localPreOrderEndTime: preOrderEndTime,
			localPaymentStartDate: paymentStartDate,
			localPaymentStartTime: paymentStartTime,
			localPaymentEndDate: paymentEndDate,
			localPaymentEndTime: paymentEndTime,
			localReleaseDate: releaseDate,
			localReleaseTime: releaseTime,
			localReleaseTitleEn: releaseTitleEn,
			localReleaseTitleTh: releaseTitleTh,
			localReleaseDescriptionEn: releaseDescriptionEn,
			localReleaseDescriptionTh: releaseDescriptionTh,
		};
	},
	computed: {
		minimumRemainingPaymentDate() {
			if (this.localPreOrderStartDate) {
				return dayjs(this.localPreOrderStartDate).toDate();
			}

			return new Date();
		},
		isPaymentOptionFullPriceOnly() {
			return this.preOrderPaymentOption === PREORDER_PAYMENT.FULL_PRICE_ONLY;
		},
		paymentStartDateLabel() {
			return `Start date${!this.isPaymentOptionFullPriceOnly ? '*' : ''}`;
		},
		paymentStartTimeLabel() {
			return `Start time${!this.isPaymentOptionFullPriceOnly ? '*' : ''}`;
		},
	},
	methods: {
		getStatusBadgeTitle(value) {
			return PREORDER_TITLE.PERIODS_STATUSES[value];
		},
		getStatusBadgeColor(value) {
			return PREORDER_COLOR.PERIODS_STATUSES[value];
		},
		getTimeValidationMessage(validateItem) {
			if (validateItem.hasOwnProperty('required') && !validateItem.required) {
				return this.$t('global.error.required');
			}

			if (!validateItem.timeFormat) {
				return 'Time not valid';
			}

			if (!validateItem.after || !validateItem.minTime) {
				return 'Time is past';
			}

			return null;
		},
		handlePreOrderStartDateChange(value) {
			// If the period start date is after the period end date, re-initail the period end date
			if (this.localPreOrderEndDate && dayjs(value).isAfter(dayjs(this.localPreOrderEndDate))) {
				this.localPreOrderEndDate = value;
			}

			// If the period start date is after the remaining payment start date, re-initail the remaining payment start date
			if (this.localPaymentStartDate && dayjs(value).isAfter(dayjs(this.localPaymentStartDate))) {
				this.localPaymentStartDate = value;
			}

			// If the period start date is after the remaining payment end date, re-initail the remaining payment end date
			if (this.localPaymentEndDate && dayjs(value).isAfter(dayjs(this.localPaymentEndDate))) {
				this.localPaymentEndDate = value;
			}

			this.$emit('update:preOrderStartDate', value);
		},
	},
};
</script>

<style lang="scss" scoped>
	h4 {
		font-size: rem($base-font-size);
	}

	.relase-info {
		background-color: $color-gray-100;
		border-radius: rem(4);
		padding: rem(24);

		&-description {
			color: $color-black-45;
			font-size: rem($font-size-caption);
		}
	}

	.badge-status {
		padding: rem(4) rem(12);
		line-height: rem(16);
	}
</style>
