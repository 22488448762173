<template>
	<CRow>
		<CCol>
			<h4 class="my-4">
				Pre-order label
			</h4>
			<PreOrderLabelList
				:type="PRODUCT_LABEL_TYPES.primary"
				:list="primaryLabels"
				:disabled="disableAddLabelButton"
				title="Product label for pre-order campaign"
				description="Select pre-order label for all SKUs in this pre-order campaign."
				@onAdd="openModal"
				@onDelete="handleDelete"
			/>
			<div class="pt-2">
				<hr>
			</div>
		</CCol>
		<ModalProductLabel
			ref="modal-label"
			single-select
			@onSubmit="handleSubmit"
		/>
	</CRow>
</template>

<script>
import ModalProductLabel from '@/components/ModalProductLabel.vue';
import PreOrderLabelList from '@/components/PreOrderLabelList.vue';
import { PRODUCT_LABEL_TYPES } from '../enums/productLabels';

export default {
	name: 'PreOrderLabel',

	components: {
		ModalProductLabel,
		PreOrderLabelList,
	},
	props: {
		primaryLabels: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		PRODUCT_LABEL_TYPES() {
			return PRODUCT_LABEL_TYPES;
		},
		disableAddLabelButton() {
			return this.primaryLabels && this.primaryLabels.length > 0;
		},
	},
	methods: {
		handleSubmit({ labels, type }) {
			const allLabels = [...labels];
			this.emitInputValue(allLabels, type);
		},
		handleDelete({ id, type }) {
			const allLabels = this[`${type}Labels`].filter((label) => label.id !== id);
			this.emitInputValue(allLabels, type);
		},
		openModal(type) {
			this.$refs['modal-label'].open(type);
		},
		emitInputValue(allLabels, type) {
			this.$emit(`update:${type}Labels`, allLabels);
		},
	},
};
</script>
