<template>
	<CRow class="mb-4">
		<CCol md="7">
			<h6 data-test-id="title">
				{{ title }} {{ type }}
			</h6>
			<div class="description typo-helper-text color-black-45">
				{{ description }}

				<div v-if="!isValid" class="danger mt-2">
					{{ $t('global.error.required') }}
				</div>
			</div>
		</CCol>
		<CCol md="5" class="text-right">
			<CButton
				:disabled="disabled"
				data-test-id="add-label-preview"
				class="transparent"
				color="secondary"
				@click="handleAdd"
			>
				Add label
			</CButton>
		</CCol>
		<CCol md="12">
			<ul
				v-if="activeList.length"
				class="label-preview label-preview mt-3 active-list"
				data-test-id="label-preview"
			>
				<li
					v-for="item in activeList"
					:key="item.id"
					class="label-preview-item mr-3"
				>
					<img :src="item.thumbnail" :alt="item.name">
					<CButton
						class="btn-delete-label"
						@click="handleDelete(item.id)"
					>
						<CIcon name="cil-trash" />
					</CButton>
				</li>
			</ul>
			<div v-if="inactiveList.length" class="mt-2">
				<h6 class="typo-label color-black-45">
					Inactive label
				</h6>
				<ul class="label-preview label-preview mt-1 pb-0 inactive-list">
					<li
						v-for="item in inactiveList"
						:key="item.id"
						class="label-preview-item mr-3"
					>
						<img :src="item.thumbnail" :alt="item.name">
						<CButton
							class="btn-delete-label"
							@click="handleDelete(item.id)"
						>
							<CIcon name="cil-trash" />
						</CButton>
					</li>
				</ul>
			</div>
		</CCol>
	</CRow>
</template>

<script>
import { PRODUCT_LABEL_TYPES } from '../enums/productLabels';

export default {
	name: 'PreOrderLabelList',

	props: {
		type: {
			type: String,
			default: PRODUCT_LABEL_TYPES.primary,
		},
		title: {
			type: String,
			default: '',
		},
		description: {
			type: String,
			default: '',
		},
		list: {
			type: Array,
			default: () => [],
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		isValid: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		activeList() {
			return this.list.filter((label) => label.isActive);
		},
		inactiveList() {
			return this.list.filter((label) => !label.isActive);
		},
	},
	methods: {
		handleAdd() {
			this.$emit('onAdd', this.type);
		},
		handleDelete(id) {
			this.$emit('onDelete', { id, type: this.type });
		},
	},
};
</script>

<style lang="scss" scoped>
	.label-preview {
		list-style: none;
		margin-bottom: 0;
		padding-left: 0;

		// label-preview-item
		&-item {
			width: rem(60);
			height: rem(60);
			position: relative;
			display: inline-block;
			border-radius: 2px;
			border: 1px solid $color-gray-300;
			margin-bottom: rem(12);

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}

		// label-preview-secondary
		&-secondary {
			.label-preview-item {
				width: rem(234);
			}
		}
	}

	.btn-delete-label {
		position: absolute;
		right: rem(4);
		bottom: rem(4);
		width: rem(20);
		height: rem(20);
		box-shadow: none;
		padding: 0;
		border-radius: 2px;
		background-color: $color-black-45;

		svg {
			width: rem(12) !important;
			color: $color-white;
		}
	}

	.inactive-list {
		background-color: $color-gray-100;
		border: 1px solid $color-gray-400;
		border-radius: 4px;
		padding: rem(12);
	}
</style>
